import React from "react";
import "./aboutacademy.css";
import { TbBallBasketball } from "react-icons/tb";
import teamImage from "../../Assets/aboutacademy.JPG";
import teamImage2 from "../../Assets/aboutimage.JPG";
import coach from "../../Assets/coach.jpg";
import { RiDoubleQuotesR } from "react-icons/ri";
const Aboutacademy = () => {
  return (
    <>
      <div className="about-flexdiv">
        <div className="about-text">
          <div className="about-question">
            <TbBallBasketball className="basketball-icon" />
            <h3>Why Join Us?</h3>
          </div>
          <h1>We Don't Just Coach, We Craft World Class-Champions</h1>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam
            sunt ipsa placeat dolores, similique dignissimos incidunt excepturi
            officia facere ea eaque commodi nisi nostrum voluptatem iusto magni
            laboriosam repellat adipisci voluptates veniam labore minima
            quaerat.
          </p>
          <div className="coach-text">
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam
              sunt ipsa placeat dolores, similique dignissimos incidunt
              excepturi officia facere ea eaque commodi nisi nostrum voluptatem
              iusto magni laboriosam repellat adipisci voluptates veniam labore
              minima quaerat.
            </p>
            <div className="coach-info">
            <img src={coach} alt="" />
            <div>
              <h3>Prashant Sharma</h3>
              <p>Head Coach</p>
            </div>
            </div>
            <RiDoubleQuotesR className="quotes-icon" />
          </div>
        </div>
        <div className="about-sectionTwo">
          <img className="about-imageone" src={teamImage} alt="" />
          <img className="about-imagetwo" src={teamImage2} alt="" />
        </div>
      </div>
    </>
  );
};

export default Aboutacademy;
